import { RoleType } from '../data/UserMetadata';
import { LinkType, MY_PROFILE, CHANGE_PASSWORD, MANAGE_TEAM } from '../constants/linkConstants';

export const BASE_URL = 'https://beta-pdx.assessments.security.amazon.dev';
export const DEV_IRM_URL = 'https://dev.sn.irm.a2z.com';
export const UAT_IRM_URL = 'https://uat.sn.irm.a2z.com';
export const PROD_IRM_URL = 'https://irm.a2z.com';
export const WIKI_URL = 'https://w.amazon.com/bin/view/ThirdPartySecurity/Process#HTPSReviewOverview';
export const FAQ_URL = 'https://tpspublic.s3.amazonaws.com/Third+Party+Portal+FAQs+Overall.pdf';
export const REPORT_A_BUG_URL = 'https://issues.amazon.com/issues/create?template=b9322903-e2f0-4502-97c2-df1d37440c32';
export const REPORT_A_FEATURE_URL =
  'https://issues.amazon.com/issues/create?template=f6474f8e-a09f-4526-bf91-f2c70e19ee8e';
export const NEW_ASSESSMENT_SYS_ID = '8b0705d62101dd104fbd8d392791cf05';
export const LEARN_MORE = 'https://w.amazon.com/bin/view/ThirdPartySecurity/Process/'; //different portals of IRM users
export const TPS_CATALOG_QUERY_PARAMETER = '$pa_dashboard.do?sysparm_dashboard=d6bdc074b89cb4982b53212c3ec56bff';
export const DACE_URL = 'https://prod.dace.infosec.amazon.dev';
export const REPORT_A_SECURITY_EVENT = 'mailto:security@amazon.com?subject=Security Issue Report&body=Please describe the security issue:';

export enum PortalType {
  TPS = 'tps',
  SVDP = 'svdp',
}
export enum SnowUrlType {
  ASSESSMENT = 'assessment',
  ISSUE = 'issue',
  TASK = 'task',
  CONSULT = 'consult',
  SURVEY = 'survey',
  DDQ = 'ddq',
  SECURITY_FOLLOWUP = 'security_followup',
  NEW_ASSESSMENT = 'new_assessment',
  INTAKE = 'intake',
  NEW_CONSULT = 'new_consult',
  VENDOR_INVITE_CONTACT = 'vendor_invite_contact',
  VENDOR_PROFILE_EDIT_CONTACT = 'vendor_profile_edit_contact',
  VENDOR_TEAM_MANAGEMENT = 'manage_team',
  MY_PROFILE = 'my_profile',
  HOME = 'home',
  LOGOUT = 'logout',
  TPS_CATALOG = 'tps_catalog',
}

type UrlType = SnowUrlType;

export interface UrlParams {
  idKey?: string;
  queryParams?: string[][];
  path?: string;
  typeId?: string;
}

const ASSESSMENT_TABLE_TPS: UrlParams = {
  queryParams: [
    ['view', 'sp'],
    ['table', 'sn_vdr_risk_asmt_vdr_tiering_assessment'],
    ['id', 'tps_vta'],
  ],
  idKey: 'sys_id',
};

const ISSUE_TABLE_TPS: UrlParams = {
  queryParams: [
    ['view', 'sp'],
    ['table', 'sn_vdr_risk_asmt_issue'],
    ['id', 'tps_vri'],
  ],
  idKey: 'sys_id',
};

const TASK_TABLE_TPS: UrlParams = {
  queryParams: [
    ['view', 'sp'],
    ['table', 'sn_vdr_risk_asmt_task'],
    ['id', 'tps_vrt'],
  ],
  idKey: 'sys_id',
};

const CONSULT_TABLE_TPS: UrlParams = {
  queryParams: [
    ['view', 'sp'],
    ['id', 'tps_vrt_consults'],
    ['table', 'sn_vdr_risk_asmt_task'],
  ],
  idKey: 'sys_id',
};

const SURVEY_PAGE: UrlParams = {
  //for survey it's same query param for TPS and SVDP so in the naming convention didn't added TPS
  queryParams: [['id', 'take_survey_tps']],
  idKey: 'instance_id',
};

//sample link to DDQ TPS portal for 1P user persona
// https://uat.sn.irm.a2z.com/tps?id=svdp_questions&instance_id=9c243bd7cd7a6d50cb59b47edb24c296&notes_table=sn_vdr_risk_asmt_m2m_asmt_questionnaire_template&notes_sys_id=54243bd7cd7a6d50cb59b47edb24c293
//dynamic - domain name(dev, uat, irm), instance_id, note_sys_id
const DDQ_TPS: UrlParams = {
  queryParams: [
    ['id', 'svdp_questions'],
    ['notes_table', 'sn_vdr_risk_asmt_m2m_asmt_questionnaire_template'],
  ],
  idKey: 'instance_id',
  typeId: 'notes_sys_id',
};

//https://uat.sn.irm.a2z.com/tps?id=tps_survey_page&type_id=308a461ab9dd101009693100d97e4b2c&instance_id=1096e55d719d2510f4293483a5a47eae
//dynamic - domain & instance_id
const SECURITY_FOLLOWUP_TPS: UrlParams = {
  queryParams: [['id', 'tps_survey_page']],
  idKey: 'instance_id',
  typeId: 'typeId',
};

const PROFILE_TPS: UrlParams = {
  queryParams: [['id', 'user_profile']],
  idKey: 'sys_id',
};

const NEW_ASSESSMENT_URL_TPS: UrlParams = {
  queryParams: [['id', 'tps_intake_rp_v2']],
  idKey: 'sys_id',
};

const NEW_CONSULT_URL_TPS: UrlParams = {
  queryParams: [['id', 'tps_inquiry']],
};

const tps_map = new Map<UrlType, UrlParams>();
tps_map.set(SnowUrlType.ASSESSMENT, ASSESSMENT_TABLE_TPS);
tps_map.set(SnowUrlType.ISSUE, ISSUE_TABLE_TPS);
tps_map.set(SnowUrlType.TASK, TASK_TABLE_TPS);
tps_map.set(SnowUrlType.CONSULT, CONSULT_TABLE_TPS);
tps_map.set(SnowUrlType.SURVEY, SURVEY_PAGE);
tps_map.set(SnowUrlType.DDQ, DDQ_TPS);
tps_map.set(SnowUrlType.SECURITY_FOLLOWUP, SECURITY_FOLLOWUP_TPS);
tps_map.set(SnowUrlType.NEW_ASSESSMENT, NEW_ASSESSMENT_URL_TPS);
tps_map.set(SnowUrlType.NEW_CONSULT, NEW_CONSULT_URL_TPS);
tps_map.set(SnowUrlType.MY_PROFILE, PROFILE_TPS);

const ASSESSMENT_TABLE_SVDP: UrlParams = {
  queryParams: [
    ['view', 'sp'],
    ['table', 'sn_vdr_risk_asmt_vdr_tiering_assessment'],
    ['id', 'svdp_vta'],
  ],
  idKey: 'sys_id',
};

const ISSUE_TABLE_SVDP: UrlParams = {
  queryParams: [
    ['view', 'sp'],
    ['table', 'sn_vdr_risk_asmt_issue'],
    ['id', 'svdp_task_conversation'],
  ],
  idKey: 'sys_id',
};

const VENDOR_INVITE_CONTACT_SVDP: UrlParams = {
  queryParams: [
    ['view', 'sp'],
    ['id', 'svdp_profile_edit'],
    ['table', 'vm_vdr_contact'],
  ],
  idKey: 'sys_id',
};

const PROFILE_SVDP: UrlParams = {
  queryParams: [
    ['id', 'svdp_profile'],
    ['from', 'profile'],
  ],
  idKey: 'sys_id',
};

const VENDOR_PROFILE_EDIT_SVDP: UrlParams = {
  queryParams: [
    ['view', 'sp'],
    ['id', 'svdp_profile_edit'],
  ],
  idKey: 'sys_id',
};

//sample link to DDQ TPS portal for 1P user persona
//https://irm.a2z.com/svdp?id=svdp_questions&instance_id=c5c48021c5133450f87246e63f119802&notes_table=sn_vdr_risk_asmt_m2m_asmt_questionnaire_template&notes_sys_id=cdc44021c5133450f87246e63f1198fe
//dynamic - domain name(dev, uat, irm), instance_id, note_sys_id
const DDQ_SVDP: UrlParams = {
  queryParams: [
    ['id', 'svdp_questions'],
    ['notes_table', 'sn_vdr_risk_asmt_m2m_asmt_questionnaire_template'],
  ],
  idKey: 'instance_id', //tpsRecordSystemId
  typeId: 'notes_sys_id',
};

const VENDOR_TEAM_MANAGEMENT_SVDP: UrlParams = {
  queryParams: [['id', 'svdp_team']],
};

const LOGOUT_SVDP: UrlParams = {
  queryParams: [['id', 'svdp_logout']],
};

const svdp_map = new Map<UrlType, UrlParams>();

svdp_map.set(SnowUrlType.ASSESSMENT, ASSESSMENT_TABLE_SVDP);
svdp_map.set(SnowUrlType.ISSUE, ISSUE_TABLE_SVDP);
svdp_map.set(SnowUrlType.SURVEY, SURVEY_PAGE);
svdp_map.set(SnowUrlType.DDQ, DDQ_SVDP);
svdp_map.set(SnowUrlType.VENDOR_INVITE_CONTACT, VENDOR_INVITE_CONTACT_SVDP);
svdp_map.set(SnowUrlType.VENDOR_PROFILE_EDIT_CONTACT, VENDOR_PROFILE_EDIT_SVDP);
svdp_map.set(SnowUrlType.VENDOR_TEAM_MANAGEMENT, VENDOR_TEAM_MANAGEMENT_SVDP);
svdp_map.set(SnowUrlType.MY_PROFILE, PROFILE_SVDP);
svdp_map.set(SnowUrlType.LOGOUT, LOGOUT_SVDP);

export const QUERY_MAP = new Map<PortalType, Map<UrlType, UrlParams>>();
QUERY_MAP.set(PortalType.TPS, tps_map);
QUERY_MAP.set(PortalType.SVDP, svdp_map);

export interface UserVisibility {
  role?: RoleType;
  link: LinkType;
}

export const NAV_LINKS: UserVisibility[] = [
  {
    role: RoleType.REQUESTER,
    link: { label: 'wiki', href: WIKI_URL },
  },
  {
    link: { label: 'report_security_event', href: REPORT_A_SECURITY_EVENT },
    role: RoleType.VENDOR,
  },
  {
    link: { label: 'faq', href: FAQ_URL },
  },
  {
    role: RoleType.REQUESTER,
    link: { label: 'report_a_bug', href: REPORT_A_BUG_URL },
  },
  {
    role: RoleType.REQUESTER,
    link: { label: 'request_a_feature', href: REPORT_A_FEATURE_URL },
  },
];

export const PROFILE_SETTINGS: UserVisibility[] = [
  {
    role: RoleType.VENDOR,
    link: MANAGE_TEAM,
  },
  {
    link: MY_PROFILE,
  },
  {
    role: RoleType.VENDOR,
    link: CHANGE_PASSWORD,
  },
];
